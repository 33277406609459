<template>
<b-card
    no-body
>
  <div class="m-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
        <label>Entries</label>
        <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
        />
        <b-button
            variant="primary"
            :to="{ name: 'owners-add'}"
        >
            Add Owner
        </b-button>
        </b-col>

        <!-- Search -->
        <b-col
        cols="12"
        md="6"
        >
        <div class="d-flex align-items-center justify-content-end">
            <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
            />
            
        </div>
      </b-col>
    </b-row>
  </div>
  <b-table
    ref="refListTable"
    :items="fetchOwners"
    responsive
    striped
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDesc"
    class="position-relative"
  >
    <template #cell(is_active)="data">

      <b-form-checkbox
          v-if="data.item.is_active == 1"
          checked="true"
          class="custom-control-primary"
          name="is_active"
          switch
        />
      <b-form-checkbox
          v-else
          checked="false"
          class="custom-control-primary"
          name="is_active"
          switch
        />
    </template>
    <template #cell(name)="data">
      <b-link
       :to="{ name: 'owners-edit', params: { id: data.item.id } }"
        class="font-weight-bold d-block text-nowrap"
      >
        {{ data.item.firstname }} {{ data.item.lasttname }}
      </b-link>
      <small class="text-muted">{{ data.item.email }}</small>
     
      
     
      

    </template>
     <template #cell(avatar)="data">
             
               
                <b-img class="img_property" v-if="data.item.avatar_url "
                    :src="data.item.avatar_url"
                    fluid
                />
        <b-img  class="img_property" v-else
                    :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                    fluid
                />
            </template>
    <template #cell(actions)="data">
      <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item :to="{ name: 'owners-edit', params: { id: data.item.id } }">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteconfirm(data.item.id)">
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        
    </template>
  </b-table>
   <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totals"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>    
</b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import useOwnersList from './useOwnersList'
import ownerStoreModule from '../ownerStoreModule'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    useOwnersList,
    ownerStoreModule,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BImg,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BFormCheckbox
  },
  setup() {
    const OWNER_APP_STORE_MODULE_NAME = 'app-owner'

    // Register module
    if (!store.hasModule(OWNER_APP_STORE_MODULE_NAME)) store.registerModule(OWNER_APP_STORE_MODULE_NAME, ownerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OWNER_APP_STORE_MODULE_NAME)) store.unregisterModule(OWNER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchOwners,
      
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      deleteData,
      refetchData,
    } = useOwnersList()

    return {
      fetchOwners,
      
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      deleteData,
      refetchData,
    }
  },
  directives: {
    Ripple,
  },
  methods:{
    deleteconfirm(id){
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Property ID.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            this.deleteData(id)
          }
          console.log('value',value);
          //this.deleteData(id)
        })
      
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
